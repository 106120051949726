import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  getAuthenticated,
  getCurrentUser,
  logoutCurrentUser,
  getLogo,
} from '../../services/auth-api';
import {
  setTokens,
  removeStore,
  getAccessToken,
  getRefreshToken,
} from '../../services/localStorage-api';
import { getKeys, getOrganizationById } from '../../services/orgs-api';
import { getCountUnreadMessages } from '../../services/support-service-api';
import authContext from './context';
import { createRequestError, statusCodes } from '../../utils';

export default function Provider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [perms, setPerms] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [currentLogo, setCurrentLogo] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const [unreadMsgCount, setUnreadMsgCount] = useState(null);
  const [isPaginatedTable, setIsPaginatedTable] = useState(false);
  const [keys, setKeys] = useState(null);

  const token = getAccessToken();
  const refresh = getRefreshToken();

  const getUnreadMsgCount = useCallback(async () => {
    if (!token) {
      return;
    }
    try {
      const { data: all } = await getCountUnreadMessages();
      const { data: personal } = await getCountUnreadMessages({
        only_my_complaints: 1,
      });

      setUnreadMsgCount({
        all: all.count_unread_messages,
        personal: personal.count_unread_messages,
      });
    } catch (error) {
      setUnreadMsgCount(null);
      console.log(error.message);
    }
  }, [token]);

  useEffect(() => {
    const getCredentials = async () => {
      if (!token) {
        setIsLoggedIn(false);
        return;
      }
      setIsLoggedIn(true);
      try {
        const user = await getCurrentUser();

        if (user) {
          setCurrentUser(user.data);
          setPerms(user.data.permissions);
          setCurrentCustomer(user.data.organization);
        }
      } catch (error) {
        createRequestError();
      }
    };

    const getCustomerLogo = async () => {
      if (!token) {
        return;
      }
      try {
        const { data } = await getLogo();
        setCurrentLogo(data);
      } catch (error) {
        setCurrentLogo(null);
        console.log(error.message);
      }
    };

    getCredentials();
    getCustomerLogo();
    getUnreadMsgCount();
    setIsChecking(false);
  }, [token, getUnreadMsgCount]);

  useEffect(() => {
    const isPrivateMode = process.env.REACT_APP_PRIVATE_MODE === 'true';

    if (!isPrivateMode) return;

    const fetchKeys = async () => {
      try {
        const data = await getKeys();

        if (data.data) {
          setKeys(data.data);
        }
      } catch (error) {
        setKeys(null);
        console.log(error.message);
      }
    };

    fetchKeys();
  }, []);

  const onLogIn = async data => {
    try {
      const res = await getAuthenticated(data);
      if (res.status === statusCodes.HTTP_200_OK) {
        setTokens(res.data.access, res.data.refresh);
        setIsLoggedIn(true);
      }
    } catch (err) {
      if (err.response.status === statusCodes.HTTP_401_UNAUTHORIZED) {
        createRequestError('Ошибка: Неверный логин или пароль');
      } else {
        createRequestError();
      }
      console.log(err.message);
    }
  };

  const onLogOut = async () => {
    try {
      await logoutCurrentUser({ refresh });
      setCurrentUser(null);
      setPerms(null);
      setCurrentCustomer(null);
      removeStore();
      setIsLoggedIn(false);
    } catch (err) {
      createRequestError();
      console.log(err.message);
    }
  };

  const getCustomerLogo = async () => {
    if (!token) {
      return;
    }
    try {
      const { data } = await getLogo();
      setCurrentLogo(data);
    } catch (error) {
      setCurrentLogo(null);
      console.log(error.message);
    }
  };

  const renewUser = async userId => {
    if (parseInt(userId) === currentUser.id) {
      const user = await getCurrentUser();
      getCustomerLogo();
      if (user) {
        setCurrentUser(user.data);
        setPerms(user.data.permissions);
        setCurrentCustomer(user.data.organization);
      }
    }
  };

  const renewCustomer = async orgId => {
    if (parseInt(orgId) === currentCustomer.id) {
      const customer = await getOrganizationById(currentCustomer.id);

      setCurrentCustomer(customer.data);
    }
  };

  return (
    <authContext.Provider
      value={{
        isLoggedIn,
        onLogIn,
        onLogOut,
        isChecking,
        currentCustomer,
        currentUser,
        perms,
        renewUser,
        renewCustomer,
        currentLogo,
        unreadMsgCount,
        getUnreadMsgCount,
        isPaginatedTable,
        setIsPaginatedTable,
        keys,
        setKeys,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.node,
};
