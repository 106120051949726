import { useContext, lazy, Suspense } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PublicRoute from './components/routes/PublicRoute';
import PrivateRoute from './components/routes/PrivateRoute';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CustomLoader from './components/CustomLoader/CustomLoader';
import LoginView from './views/LoginView';
import { checkEveryPermissions, checkSomePermissions } from './utils';

import authContext from './context/auth/context';
import { routes } from './helpers/constants.js';

const MainView = lazy(() => import('./views/MainView.jsx'));
const AddTaskView = lazy(() => import('./views/actions_views/AddTaskView'));
const TaskInfoView = lazy(() => import('./views/TaskInfoView'));
const CaseInfoView = lazy(() => import('./views/actions_views/CaseInfoView'));
const RulesView = lazy(() => import('./views/RulesView'));
const UploadRuleView = lazy(() =>
  import('./views/actions_views/UploadRuleView'),
);
const EditRuleView = lazy(() => import('./views/actions_views/EditRuleView'));
const AddEditDefectView = lazy(() =>
  import('./views/actions_views/AddEditDefectView'),
);
const UploadReasonView = lazy(() =>
  import('./views/actions_views/UploadReasonView'),
);
const UploadGreenLpuView = lazy(() =>
  import('./views/actions_views/UploadGreenLpuView'),
);
const CatalogsView = lazy(() => import('./views/CatalogsView'));
const ComplaintsView = lazy(() => import('./views/ComplaintsView'));
const ComplaintHistoryView = lazy(() =>
  import('./views/actions_views/ComplaintHistoryView'),
);
const UploadNormativeActsView = lazy(() =>
  import('./views/actions_views/UploadNormativeActsView'),
);
const UnloadingView = lazy(() => import('./views/UnloadingView'));
const SystemRulesView = lazy(() => import('./views/SystemRulesView'));
const EditSystemRuleView = lazy(() =>
  import('./views/actions_views/EditSystemRuleView'),
);
const AddSystemRuleView = lazy(() =>
  import('./views/actions_views/AddSystemRuleView'),
);
const AddSystemComplexRuleView = lazy(() =>
  import('./views/actions_views/AddSystemComplexRuleView'),
);
const EditSystemComplexRuleView = lazy(() =>
  import('./views/actions_views/EditSystemComplexRuleView'),
);
const UploadSystemRulesView = lazy(() =>
  import('./views/actions_views/UploadSystemRulesView'),
);
const UploadDefectView = lazy(() =>
  import('./views/actions_views/UploadDefectView'),
);
const UploadTaskMeeView = lazy(() =>
  import('./views/subviews/tasks_subviews/UploadTaskMeeView'),
);
const UsersView = lazy(() => import('./views/UsersView'));
const UserEditView = lazy(() => import('./views/UserEditView'));
const OrgsView = lazy(() => import('./views/OrgsView'));
const OrgsAddView = lazy(() => import('./views/OrgsAddView'));
const OrgsEditView = lazy(() => import('./views/OrgsEditView'));
const Page404View = lazy(() => import('./views/Page404View'));
const KeyChangeView = lazy(() => import('./views/KeyChangeView'));

function App() {
  const { isChecking, perms, keys } = useContext(authContext);

  const { pathname } = useLocation();

  return (
    <>
      {!isChecking && (
        <>
          {pathname !== '/login' && <Header />}
          <Suspense fallback={<CustomLoader />}>
            <Routes>
              <Route path="/" exact element={<Navigate to="/login" />} />
              <Route
                path="/login"
                element={
                  <PublicRoute redirectTo={routes.DEFAULT_TASK}>
                    <LoginView />
                  </PublicRoute>
                }
              />
              <Route
                path="/tasks"
                element={
                  <PrivateRoute
                    isAllowed={perms?.includes('tasks.view_task')}
                  />
                }
              >
                <Route index element={<MainView />} />
                <Route
                  path="add"
                  element={
                    <PrivateRoute isAllowed={perms?.includes('tasks.add_task')}>
                      <AddTaskView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":taskId"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'tasks.view_task',
                          'mainapp.view_case',
                          'mainapp.change_case',
                          'mainapp.view_row',
                          'mainapp.change_row',
                        ],
                        perms,
                      )}
                    />
                  }
                >
                  <Route index element={<TaskInfoView />} />
                  <Route path="upload_mee" element={<UploadTaskMeeView />} />
                  <Route
                    path="case/:caseId/:query?"
                    element={
                      <PrivateRoute
                        isAllowed={checkEveryPermissions(
                          [
                            'tasks.view_task',
                            'mainapp.view_case',
                            'mainapp.change_case',
                            'mainapp.view_row',
                            'mainapp.change_row',
                          ],
                          perms,
                        )}
                      >
                        <CaseInfoView />
                      </PrivateRoute>
                    }
                  />
                </Route>
              </Route>
              <Route
                path="/rules//*"
                element={
                  <PrivateRoute
                    isAllowed={perms?.includes('mainapp.view_clientrule')}
                  >
                    <RulesView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/catalogs//*"
                element={
                  <PrivateRoute
                    isAllowed={checkSomePermissions(
                      [
                        'mainapp.view_division',
                        'mainapp.view_lpu',
                        'mainapp.view_normativeactrow',
                        'mainapp.view_normativeactanalysis',
                      ],
                      perms,
                    )}
                  >
                    <CatalogsView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/complaints//*"
                element={
                  <PrivateRoute
                  // perms - ?
                  // isAllowed={checkSomePermissions(
                  //   [
                  //     'mainapp.view_division',
                  //     'mainapp.view_lpu',
                  //     'mainapp.view_normativeactrow',
                  //     'mainapp.view_normativeactanalysis',
                  //   ],
                  //   perms,
                  // )}
                  >
                    <ComplaintsView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/complaint/:complaintId"
                element={
                  <PrivateRoute
                  // perms - ?
                  // isAllowed={checkSomePermissions(
                  //   [
                  //     'mainapp.view_division',
                  //     'mainapp.view_lpu',
                  //     'mainapp.view_normativeactrow',
                  //     'mainapp.view_normativeactanalysis',
                  //   ],
                  //   perms,
                  // )}
                  >
                    <ComplaintHistoryView />
                  </PrivateRoute>
                }
              />

              {
                <Route
                  path="/unloading"
                  element={
                    <PrivateRoute
                      isAllowed={perms?.includes('mainapp.view_unloading')}
                    >
                      <UnloadingView />
                    </PrivateRoute>
                  }
                />
              }
              {
                <Route
                  path="/key_change"
                  element={
                    <PrivateRoute
                      isAllowed={
                        Boolean(keys?.private_mode) &&
                        checkEveryPermissions(
                          [
                            'mainapp.view_keyset',
                            'mainapp.change_keyset',
                            'mainapp.delete_keyset',
                            'mainapp.add_keyset',
                          ],
                          perms,
                        )
                      }
                    >
                      <KeyChangeView />
                    </PrivateRoute>
                  }
                />
              }
              <Route
                path="/system//*"
                element={
                  <PrivateRoute
                    isAllowed={perms?.includes('mainapp.view_rule')}
                  >
                    <SystemRulesView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <PrivateRoute
                    isAllowed={perms?.includes('mainapp.change_profile')}
                  />
                }
              >
                <Route index element={<UsersView />} />
                <Route
                  path="add"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        ['mainapp.change_profile', 'mainapp.add_profile'],
                        perms,
                      )}
                    >
                      <UserEditView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":userId/edit"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        ['mainapp.change_profile'],
                        perms,
                      )}
                    >
                      <UserEditView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":userId/password"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        ['mainapp.change_profile'],
                        perms,
                      )}
                    >
                      <UserEditView />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="/organizations"
                element={
                  <PrivateRoute
                    isAllowed={perms?.includes(
                      'mainapp.view_clientorganization',
                    )}
                  />
                }
              >
                <Route index element={<OrgsView />} />
                <Route
                  path="add"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.add_clientorganization',
                          'mainapp.view_clientorganization',
                        ],
                        perms,
                      )}
                    >
                      <OrgsAddView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path=":orgId/edit"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.change_clientorganization',
                          'mainapp.view_clientorganization',
                        ],
                        perms,
                      )}
                    >
                      <OrgsEditView />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route path="/upload" element={<PrivateRoute />}>
                <Route index element={<Navigate to={routes.DEFAULT_TASK} />} />
                <Route
                  path="rule"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.change_clientrule',
                          'mainapp.view_clientrule',
                          'mainapp.add_clientrule',
                        ],
                        perms,
                      )}
                    >
                      <UploadRuleView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="defect"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.add_defect',
                          'mainapp.change_defect',
                          'mainapp.view_defect',
                        ],
                        perms,
                      )}
                    >
                      <UploadDefectView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="reason"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.add_declinereason',
                          'mainapp.change_declinereason',
                          'mainapp.view_declinereason',
                        ],
                        perms,
                      )}
                    >
                      <UploadReasonView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="green_channel"
                  element={
                    <PrivateRoute
                      isAllowed={perms?.includes('mainapp.view_lpu')}
                    >
                      <UploadGreenLpuView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="normative_acts"
                  element={
                    <PrivateRoute
                      isAllowed={perms?.includes(
                        'mainapp.change_normativeactrow',
                      )}
                    >
                      <UploadNormativeActsView />
                    </PrivateRoute>
                  }
                />
                {/* добавить, когда появится функционал */}
                {/* <Route path="na_analysis" element={<UploadNaAnalysisView />} /> */}
                <Route
                  path="system_rule"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.view_rule',
                          'mainapp.add_rule',
                          'mainapp.change_rule',
                        ],
                        perms,
                      )}
                    >
                      <UploadSystemRulesView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="system_complex_rule"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.view_specificrule',
                          'mainapp.add_specificrule',
                          'mainapp.change_specificrule',
                        ],
                        perms,
                      )}
                    >
                      <UploadSystemRulesView />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route path="/edit" element={<PrivateRoute />}>
                <Route index element={<Navigate to={routes.DEFAULT_TASK} />} />
                <Route
                  path="rule/:ruleId"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.view_clientrule',
                          'mainapp.change_clientrule',
                        ],
                        perms,
                      )}
                    >
                      <EditRuleView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="defect/:defectId"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        ['mainapp.change_defect', 'mainapp.view_defect'],
                        perms,
                      )}
                    >
                      <AddEditDefectView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="system_rule/:ruleId"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        ['mainapp.view_rule', 'mainapp.change_rule'],
                        perms,
                      )}
                    >
                      <EditSystemRuleView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="system_complex_rule/:ruleId"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.view_specificrule',
                          'mainapp.change_specificrule',
                        ],
                        perms,
                      )}
                    >
                      <EditSystemComplexRuleView />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route path="/add" element={<PrivateRoute />}>
                <Route index element={<Navigate to={routes.DEFAULT_TASK} />} />
                <Route
                  path="defect"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.add_defect',
                          'mainapp.change_defect',
                          'mainapp.view_defect',
                        ],
                        perms,
                      )}
                    >
                      <AddEditDefectView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="system_rule"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.add_rule',
                          'mainapp.change_rule',
                          'mainapp.view_rule',
                        ],
                        perms,
                      )}
                    >
                      <AddSystemRuleView />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="system_complex_rule"
                  element={
                    <PrivateRoute
                      isAllowed={checkEveryPermissions(
                        [
                          'mainapp.view_specificrule',
                          'mainapp.add_specificrule',
                        ],
                        perms,
                      )}
                    >
                      <AddSystemComplexRuleView />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="*"
                element={
                  <PrivateRoute>
                    <Page404View offset="148px" />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Suspense>
          <Footer />
        </>
      )}
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
